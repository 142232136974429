export default {
  locale: {
    ariaLabelNavigation: 'Sprachnavigation',
    ariaLabelList: 'Sprache wechseln',
    infoCurrentLanguage: `Sprachnavigation. Die aktuelle Sprache ist deutsch.`,
    showPageInLang: 'Diese Seite auf Deutsch anzeigen',
  },
  navigation: {
    menu: 'Menü',
    close: 'Schliessen',
    openOrClose: 'Öffnen / Schliessen',
    ariaLabelMainNavigation: 'Hauptnavigation',
    plantTypes: 'Anlagen',
    home: 'Übersicht',
  },
  contentHub: {
    allArticlesSlug: 'alle-typen',
    topicPlaceholder: 'Filtern nach Thema',
  },
  article: {
    lastUpdate: 'Letztes Update',
    topics: 'Themen',
    backToOverview: 'Zurück zur Übersicht',
  },
  plantType: {
    listHeader: {
      plant: 'Anlage',
      location: 'Standort',
      company: 'Firma',
      services: 'Services',
    },
  },
  chart: {
    valueSelection: 'Auswahl Kennwerte',
    comparison: {
      choose: 'Auswählen',
      part1: '',
      part2: 'mit',
      part3: 'vergleichen',
    },
  },
  project: {
    title: 'Projekt',
    ongoing: 'laufend',
    complete: 'abgeschlossen',
    facts: 'Projekt-Eckdaten',
    contribution: 'Beitrag VBSA Klimafonds',
    costs: 'Gesamtkosten Projekt',
    management: 'Projektleitung',
  },
};
