export default {
  locale: {
    ariaLabelNavigation: 'Navigation linguistique',
    ariaLabelList: 'Changer de langue',
    infoCurrentLanguage: `Navigation linguistique. La langue actuelle est l'anglais`,
    showPageInLang: 'Afficher cette page en français',
  },
  navigation: {
    menu: 'Menu',
    close: 'Fermer',
    openOrClose: 'Ouvrir / Fermer',
    ariaLabelMainNavigation: 'Navigation principale',
    plantTypes: 'Installations',
    home: 'Aperçu',
  },
  contentHub: {
    allArticlesSlug: 'tous-les-types',
    topicPlaceholder: 'Filtrer par sujet',
  },
  article: {
    lastUpdate: 'Dernière mise à jour',
    topics: 'Sujets',
    backToOverview: "Retour à l'aperçu",
  },
  plantType: {
    listHeader: {
      plant: 'Installation',
      location: 'Lieu',
      company: 'Entreprise',
      services: 'Services',
    },
  },
  chart: {
    valueSelection: 'Sélection des caractéristiques',
    comparison: {
      choose: 'Choisir',
      part1: 'comparer',
      part2: 'avec',
      part3: '',
    },
  },
  project: {
    title: 'Projet',
    ongoing: 'on cours',
    complete: 'terminé',
    facts: 'Données de base du projet',
    contribution: 'Contribution ASEC Fonds climatique',
    costs: 'Coût total du projet',
    management: 'Direction du projet',
  },
};
